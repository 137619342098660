@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: rgb(60 60 60);
}

#root{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
}

/* ---Sidebar css */
.sidebar{
    transition: 0.5s;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}
.sd-body{
    max-height: calc(100vh - 67px);
}

/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.rsw-toolbar{
    position: sticky;
    top: 0;
}

.rsw-ce{
    background-color: #757575;
    font-size: 20px;
}

.rsw-btn{
    font-size: 1.3em;
}

.drawingBuffer {
   display: none;
}
/* video{
    width: 100%!important;
} */
 #interactive video{
    width: 100%!important;
 }

@media screen and (min-width: 768px) and (max-width: 992px){
    .rsw-editor{
        max-height: 250px !important;
        min-height: 250px !important;
    }

    .rsw-btn{
        font-size: 28px !important;
    }

    .rsw-ce{
        font-size: 1.5rem !important;
    }
}